import {Component} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {LocalStorageService} from "./services/storage-service/local-storage.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MyNkap';
  language!: string;

  constructor(private translate: TranslateService,
              private localStorageService: LocalStorageService) {
    translate.setDefaultLang('en');
    this.language = this.localStorageService.get('language')! == null ? this.translate.getBrowserLang() : this.localStorageService.get('language')!;
    this.translate.use(this.language);
  }

}
