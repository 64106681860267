<div class="page_404">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="col-sm-12 col-sm-offset-1 text-center">
                    <div class="four_zero_four_big">
                        <h1 class="text-center">404</h1>
                    </div>
                    <div class="content_box_404">
                        <h3>Looks like this page went on vacation</h3>
                        <br>
                        <p class="notfound">Don't worry though, everything is still awesome</p>
                        <p>Go to <a href="#"><img src="assets/logo.PNG" style="width: 3rem; height: 3rem" alt="logo"><em>MyNkap</em></a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
