<div class="main">
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <img src="assets/phone.png" style="width: 100%;" alt="Phone">
      </div>
      <div class="col-md-7">
        <h2>{{'download-monkap.download-app' | translate}}</h2>
        <span class="mb-5">{{'download-monkap.begin' | translate}}</span>
        <br>
        <br>
        <br>
        <h5 class="mb-4">{{'download-monkap.get-app' |translate}}</h5>
        <div class="row">
          <div class="col-md-4">
            <br>
            <img class="download img-fluid" src="assets/playStore.PNG" style="width: 150px; background-color: white" alt="Playstore">
            <br>
            <img class="download img-fluid" src="assets/apple.PNG" style="width: 150px;" alt=Apple>
          </div>
          <div class="col-md-4" >
            <img src="assets/scaner.PNG"  class="img-fluid" style="width: 175px;" alt=Scaner>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
