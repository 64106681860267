import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {LocalStorageService} from "../services/storage-service/local-storage.service";
import {catchError, switchMap} from "rxjs/operators";
import {Observable, of, throwError} from "rxjs";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  refresh=false;
  baseUrl: string = environment.baseUrlPub + "auth/";

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private http:HttpClient
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const data = this.localStorageService.getStorageData();
    if(data){
      request = request.clone({
        url: request.url,
        setHeaders: {
          Authorization: `Bearer ${data.accessToken}`
        }
      });

      return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && !this.refresh) {
          this.refresh = true;
          const refreshToken = this.localStorageService.getStorageData().refreshToken
          return this.http.post(`${this.baseUrl}refreshToken`, {refreshToken:refreshToken}).pipe(
            switchMap((res: any) => {
              const newAccessToken = res.accessToken
              this.localStorageService.set('accessToken', newAccessToken)
              return next.handle(request.clone({
                setHeaders: {
                  Authorization: `Bearer ${newAccessToken}`
                }
              }));
            })
          ) as Observable<HttpEvent<any>>;
        }
        this.refresh = false;
        if (err.status === 200 && err instanceof Blob) {
          // Ignore response format errors for Blob responses with status 200
          return of(err); // Return the error as is
        } else {
          if (err.error instanceof ProgressEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            if (request.method === 'GET')
              return throwError('Unable to fetch data!! Connection Error');
            else return throwError('Unable to perform action!! Connection Error');
          } else if (err.status === 503) {
            return throwError('Service unavailable! Please try again later');
          } else {
            const error = (err && err.error && err.message && err.error.message) || err.statusText;
            return throwError(error);
          }
        }
      }));

    }


    return next.handle(request)


  }
}
