<div class="main">
  <div class="main-footer">
  <div class="container">
    <br>
    <br>
    <div class="row">
<!--      mynkap starts-->
      <div class="col-md-3">
        <a class="navbar-brand d-flex align-items-center" routerLink="/">
          <img class="monkap-logo-image" src="assets/images/logo.svg" alt="logo">
          <span class="monkap-logo ms-2">MyNkap</span></a>
        <br>
        <br>
        <p>{{'footer.media' | translate}}</p>
        <i class="fa fa-twitter fa-2x"></i>&nbsp;&nbsp;
        <i class="fa fa-instagram fa-2x"></i>&nbsp;&nbsp;
        <i class="fa fa-linkedin-square fa-2x"></i>&nbsp;&nbsp;
        <i class="fa fa-facebook fa-2x"></i>
        <br>
        <br>
        <p>{{'footer.app-scan' | translate}}</p>
        <div class="row">
          <div class="col-md-4">
            <br>
            <img class="download mb-3 img-fluid" src="assets/google-play-store.png" style="width: 100px;" alt="Google Play Stor">
            <br>
            <img class="download mb-3 img-fluid" src="assets/google-app-store.png" style="width: 100px; " alt="Google App">
          </div>
          <div class="col-md-4 mb-3" >
            <img class="mt-3 img-fluid" src="assets/scaner.PNG" style="width: 100px;" alt="Scaner">
          </div>
        </div>
      </div>
<!--      monkap ends-->

<!--      money transfer starts-->
      <div class="col-md-3">
        <h5>{{'footer.trsf' | translate}}</h5>
        <p>{{'footer.rating' | translate}}</p>
        <p>{{'footer.monandmobile' | translate}}</p>
        <p>{{'footer.montobank' | translate}}</p>
        <p>{{'footer.withdraw' | translate}}</p>
        <br>
        <h5>{{'footer.saving' | translate}}</h5>
        <p>{{'footer.psaving' | translate}}</p>
        <p>{{'footer.tsaving' | translate}}</p>
      </div>
      <!-- Money transfer ends-->


<!--      electric bill payment starts-->
      <div class="col-md-3">
        <h5>{{'footer.ebills' | translate}}</h5>
        <p>{{'footer.eneobill' | translate}}</p>
        <h5>{{'footer.waterbill' | translate}}</h5>
        <p>{{'footer.camwater' | translate}}</p>
        <h5>{{'footer.cable' | translate}}</h5>
        <p>{{'footer.dstv' | translate}}</p>
        <p>{{'footer.sub' | translate}}</p>
      </div>
      <!--      electric bill payment ends-->

<!--      phone recharge starts-->
      <div class="col-md-3">
        <h5>{{'footer.phone' | translate}}</h5>
        <p>{{'footer.mtn' | translate}}</p>
        <p>{{'footer.bluech' | translate}}</p>
        <p>{{'footer.yoomee' | translate}}</p>
        <p>{{'footer.orange' | translate}}</p>
        <br>
        <h5>{{'footer.company' | translate}}</h5>
        <span>{{'footer.about-us' | translate}}</span>
        <p>{{'footer.contact' | translate}}</p>
      </div>
      <!--      phone recharge ends-->
    </div>
    <br>
    <br>

  </div>
  </div>
  <div class="footer-end">
    <p>{{'footer.terms-conditions' | translate}}</p>
  </div>
</div>
