<div class="main pt-5">
  <div class="container">
    <h3 class="mb-5 text-color">{{'how-monkap-works.title' | translate}}</h3>
    <div class="row g-3">
      <div class="col-sm-6 col-md-4 col-l-3 col-xl-3 col-xxl-3" *ngFor="let service of monkapServicesList">
        <div class="hcard">
          <i class="{{service.icon}} rounded-icons"></i>
          <h5 class="pt-3">{{service.title  | translate}}</h5>
          <p class="fs-6 text-start">{{service.description}}</p>
          <button class="btn btn-sm learn-more-btn" (click)="learnMore(service.id)">{{'how-monkap-works.learn' | translate}}</button>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <button class="btn btn-lg learn-more-btn mb-5">{{'how-monkap-works.start' | translate}}</button>
    </div>
  </div>
</div>
