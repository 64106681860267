<div class="bg-white">
<div class="container">

  <!--  Mobile Recharge starts-->
  <div id="mobile-recharge" class="recharge">
  <div class="mb-6">
  <h4>{{'monkap-intro.moneytr' | translate}} </h4>
  <p style="font-size: 18px">{{'monkap-intro.recharge' | translate}}</p>
  </div>
  <div class="row">
        <div class="col-md-6 rcard col-xl-3 col-xxl-3 col-sm-6">
          <div class="card">
            <img class="mb-2 rounded-circle" src="assets/icons_resize/mtn.jpeg" style="width: 4rem; height: 4rem">
            <br>
            <h5>{{'monkap-intro.mtn' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.mtnp' | translate}}</span>
          </div>
        </div>
        <div class="col-md-6 rcard col-xl-3 col-xxl-3 col-sm-6">
          <div class="card">
            <img class="mb-2 rounded-circle" src="assets/icons_resize/orange.jpg" style="width: 4rem; height: 4rem">
            <br>
            <h5>{{'monkap-intro.orange' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.orangep' | translate}}</span>
          </div>
        </div>
        <div class="col-md-6 rcard col-xl-3 col-xxl-3 col-sm-6">
          <div class="card">
            <img class="mb-2 rounded-circle" src="assets/icons_resize/blue_sim.jpg" style="width: 4rem; height: 4rem">
            <br>
            <h5>{{'monkap-intro.blue' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.bluep' | translate}}</span>
          </div>
        </div>
        <div class="col-md-6 rcard col-xl-3 col-xxl-3 col-sm-6">
          <div class="card ">
            <img class="mb-2 rounded-circle" src="assets/icons_resize/yoomee.jpg" style="width: 4rem; height: 4rem">
            <br>
            <h5>{{'monkap-intro.yoomee' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.yoomeep' | translate}}</span>
          </div>
        </div>
  </div>
</div>
  <!--  Mobile Recharge ends-->

  <!--  Money transfer and Money Management start-->
  <div id="money-transfer" class="moneyT">
    <h4 >{{'monkap-intro.moneytm' | translate}}</h4>
  <p>{{'monkap-intro.moneytmp' | translate}} </p>
  <br>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-xl-6">
      <div class="row">
        <div class="col-md-6 mb-5">
          <div class="card">
            <div class="mb-2"><i class="fa-solid fa-wallet rounded-icons"></i></div>
            <br>
            <h5>{{'monkap-intro.ewallet' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.ewalletp' | translate}}</span>
          </div>
        </div>
        <div class="col-md-6 mb-5">
          <div class="card">
            <div class="mb-2"><i class="fa-sharp fa-solid fa-building-columns rounded-icons"></i></div>
            <br>
            <h5>{{'monkap-intro.btransfer' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.btransferp' | translate}}</span>
          </div>
        </div>
        <div class="col-md-6 mb-5">
          <div class="card">
            <div class="mb-2"><i class="fa-solid fa-piggy-bank rounded-icons"></i></div>
            <br>
            <h5>{{'monkap-intro.tsaving' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.tsavingp' | translate}}</span>
          </div>
        </div>
        <div class="col-md-6 mb-5">
          <div class="card">
            <div class="mb-2"><i class="fa-solid fa-people-group rounded-icons"></i></div>
            <br>
            <h5>{{'monkap-intro.njangi' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.njangip' |translate}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-l-6 d-md-none d-sm-none d-none d-sm-block d-xl-block">
      <div class="bg1">
        <img src="../assets/icons_resize/ut1.png" alt="img" >
      </div>
    </div>
  </div>
  </div>

  <!--  Money transfer and Money Management ends-->

  <!-- Bill payment starts here -->
  <div id="bill-pay" class="billpay">
    <h4 >{{'monkap-intro.bill' | translate}}</h4>
  <p>{{'monkap-intro.billp' | translate}} </p>
  <br>
  <div class="row">
    <div class="col-xl-6 d-md-none d-sm-none d-none d-sm-block d-xl-block">
      <div  class="bg  p-5">
        <img src="../assets/icons_resize/ut.png" alt="img" >
      </div>
    </div>
    <div class=" col-sm-12 col-md-12 col-xl-6">
      <div class="row">
        <div class="col-md-6 mb-5">
          <div class="card">
            <div class="mb-2"><i class="fa-solid fa-lightbulb rounded-icons"></i></div>
            <br>
            <h5>{{'monkap-intro.light' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.lightp' | translate}}</span>
          </div>
        </div>
        <div class="col-md-6 mb-5">
          <div class="card">
            <div class="mb-2"><i class="fa-solid fa-droplet rounded-icons"></i></div>
            <br>
            <h5>{{'monkap-intro.water' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.waterp' | translate}}</span>
          </div>
        </div>
        <div class="col-md-6 mb-5">
          <div class="card">
            <div class="mb-2"><i class="fa-solid fa-tv rounded-icons"></i></div>
            <br>
            <h5>{{'monkap-intro.tv' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.tvp' | translate}}</span>
          </div>
        </div>
        <div class="col-md-6 mb-5">
          <div class="card">
            <div class="mb-2"><i class="fa-solid fa-graduation-cap rounded-icons"></i></div>
            <br>
            <h5>{{'monkap-intro.school' | translate}}</h5>
            <span class="text-start">{{'monkap-intro.schoolp' |translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
<!-- Bill pay ends here -->
</div>
<br>
</div>
