import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
images:any
  constructor() { }

  ngOnInit(): void {
    this.images = [
      {path: 'https://source.unsplash.com/800x600/?nature'},
      {path: 'https://source.unsplash.com/800x600/?car'},
      {path: 'https://source.unsplash.com/800x600/?moto'},
      {path: 'https://source.unsplash.com/800x600/?fantasy'},
    ]
  }

}
