import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {LearnMoreComponent} from "./landing-page-module/components/how-monkap-works/learn-more/learn-more.component";
import {LandingPageComponent} from "./landing-page-module/landing-page/landing-page.component";
import {AuthGuard} from "./guards/auth/auth.guard";


const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent
  },

  {
    path: 'learn-more/:id',
    component: LearnMoreComponent
  },

  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },

  {
    path: 'auth',
    loadChildren: async () => await (import('./authentication-module/authentication.module')).then(m => m.AuthenticationModule)
  },

  {
    path: 'activities',
    loadChildren: async () => await (import('./activity-module/activity-module.module')).then(m => m.ActivityModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'savings',
    loadChildren: async () => await (import('./savings-module/savings.module')).then(m => m.SavingsModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'bill-payment',
    loadChildren: async () => await (import('./bill-payment-module/bill-payment-module.module')).then(m => m.BillPaymentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'move-money',
    loadChildren: () => import('./send-money-module/send-money.module').then(m => m.SendMoneyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'recharge',
    loadChildren: () => import('./recharge-mobile/recharge-mobile.module').then(m => m.RechargeMobileModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
