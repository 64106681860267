import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from "../../../services/storage-service/local-storage.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  language!: string;

  constructor(
    private translate: TranslateService,
    public localStorageService: LocalStorageService
  ) {
    translate.setDefaultLang('en');
    translate.use(translate.getBrowserLang());
  }

  ngOnInit(): void {
    this.language = this.localStorageService.get('language')! == null ? this.translate.getBrowserLang() : this.localStorageService.get('language')!;
    this.translateOnRefresh(this.language);
  }

  useLanguage(event: any) {
    this.localStorageService.set('language', event.target.value);
    this.translate.use(event.target.value);
  }

  translateOnRefresh(language: string) {
    this.translate.use(language);
  }

  toTopPage(){
    document.getElementById("main-nav-bar")?.scrollIntoView({behavior: "smooth"});
  }

  toMobileRecharge(){
    document.getElementById("mobile-recharge")?.scrollIntoView({behavior: "smooth"});
  }

  toMoneyTransfer(){
    document.getElementById("money-transfer")?.scrollIntoView({behavior: "smooth"});
  }

  toBillPay(){
    document.getElementById("bill-pay")?.scrollIntoView({behavior: "smooth"});
  }

  toWhy(){
    document.getElementById("why")?.scrollIntoView({behavior: "smooth"});
  }
}
