import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorageService} from "../../services/storage-service/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private localStorageService: LocalStorageService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.localStorageService.getToken()) {
      this.router.navigate(['auth/login']);

      return false;
    } else {
      if (this.localStorageService.checkTokenExpiration()) {
        this.router.navigate(['auth/login']);
        this.localStorageService.remove("data");
        this.localStorageService.remove("accessToken");
        return false;
      }

    }

    return true;
  }

}
