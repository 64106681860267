import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-how-monkap-works',
  templateUrl: './how-monkap-works.component.html',
  styleUrls: ['./how-monkap-works.component.css']
})
export class HowMonkapWorksComponent implements OnInit {

  monkapServicesList = [
    {
      id: 1,
      icon: "fa-solid fa-link",
      title: this.translate.instant('how-monkap-works.link-to-mynkap'),
      description: "MyNkap makes it possible for you to recharge your mobile  no matter the network carrier."
    },
    {
      id: 2,
      icon: "fa-solid fa-arrow-right-from-bracket",
      title: this.translate.instant('how-monkap-works.recharge-phone'),
      description: "Pay all your eneo, water and tv cable  bills with just clicks ans save time for something else. "
    },
    {
      id: 3,
      icon: "fa-solid fa-rectangle-list",
      title: this.translate.instant('how-monkap-works.pay-bills'),
      description: "MyNkap makes it possible for you to recharge your mobile  no matter the network carrier."
    },
    {
      id: 4,
      icon: "fa-solid fa-user-graduate",
      title: this.translate.instant('how-monkap-works.pay-fees'),
      description: "Pay all your eneo, water and tv cable  bills with just clicks ans save time for something else. "
    },
    {
      id: 5,
      icon: "fa-solid fa-arrow-right-to-bracket",
      title: this.translate.instant('how-monkap-works.save-money'),
      description: "MyNkap makes it possible for you to recharge your mobile  no matter the network carrier."
    },
    {
      id: 6,
      icon: "fa-solid fa-comments-dollar",
      title: this.translate.instant('how-monkap-works.access-withdrawals'),
      description: "Pay all your eneo, water and tv cable  bills with just clicks ans save time for something else. "
    },
    {
      id: 7,
      icon: "fa-sharp fa-solid fa-building-columns",
      title: this.translate.instant('how-monkap-works.easy-bank-transfer'),
      description: "MyNkap makes it possible for you to recharge your mobile  no matter the network carrier."
    },
    {
      id: 8,
      icon: "fa-solid fa-wallet",
      title: this.translate.instant('how-monkap-works.e-wallet-transfer'),
      description: "Pay all your eneo, water and tv cable  bills with just clicks ans save time for something else. "
    },
  ];

  constructor(
    private translate: TranslateService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  learnMore(id: number) {
    this.router.navigate(['learn-more', id])

  }

}
