<div class="bg-white m-0 p-0" id="main-nav-bar">
<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-interval="500" >
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="assets/icons_resize/l2.png" class="d-block w-100 img-fluid" alt="bg">
      <div class="carousel-caption d-block col-md-4 core">
        <h2 class="h2">{{ 'carousel-intro.mynkap-core' | translate }} <span class="digital">{{ 'carousel-intro.digital' | translate }}</span>
          {{ 'carousel-intro.solution' | translate }} <span class="digital">{{ 'carousel-intro.manage' | translate }}</span> {{ 'carousel-intro.your-finances' | translate }}</h2>
        <p>{{ 'carousel-intro.mobile-recharge-txt' | translate }}</p>
        <button type="button" class="btn1-carousel">{{ 'carousel-intro.get-started' | translate }}</button>
      </div>

    </div>
    <div class="carousel-item">
      <img src="assets/icons_resize/r3.png" class="d-block w-100 img-fluid" alt="bg">
      <div class="carousel-caption d-block col-md-6 offset-4 bills">
        <h5  class="h5">{{ 'carousel-intro.no-taxi-txt' | translate }} <span class="pay">{{ 'carousel-intro.pay-bills' | translate }}</span></h5>
        <h2  class="h2">{{ 'carousel-intro.do-it-txt' | translate }}</h2>
        <p>{{ 'carousel-intro.discover-txt' | translate }}</p>
        <button type="button" class="btn2-carousel">{{ 'carousel-intro.electricity' | translate }}</button>&nbsp;&nbsp;
        <button type="button" class="btn2-carousel">{{ 'monkap-intro.water' | translate }}</button>&nbsp;&nbsp;
        <button type="button" class="btn2-carousel">{{ 'carousel-intro.tv-cable' | translate }}</button>&nbsp;&nbsp;
      </div>
    </div>

    <div class="carousel-item">
      <img src="assets/icons_resize/n6.png" class="d-block w-100 img-fluid " alt="bg">
      <div class="carousel-caption d-block col-md-5 offset-5 transfer">
        <h2  class="h2">{{ 'carousel-intro.transfer-txt' | translate }} <span class="africa">{{ 'carousel-intro.africa' | translate }}</span></h2>
        <p>{{ 'carousel-intro.our-system-txt' | translate }}</p>
        <button type="button" class="btn3-carousel">{{ 'carousel-intro.discover-now' | translate }}</button>
      </div>
    </div>

    <div class="carousel-item">
      <img src="assets/icons_resize/r1.png" class="d-block w-100 img-fluid" alt="bg">
      <div class="carousel-caption d-block col-md-5 network">
        <h5  class="h5">{{ 'carousel-intro.whatever-txt' | translate }}</h5>
        <h2  class="h2">{{ 'carousel-intro.buy-txt' | translate }} <span class="airtime">{{ 'carousel-intro.airtime' | translate }}</span>
          {{ 'carousel-intro.or' | translate }} <span class="airtime">{{ 'carousel-intro.data' | translate }}</span>  {{ 'carousel-intro.bundle-txt' | translate }}</h2>
        <p>{{ 'carousel-intro.no-limit-txt' | translate }}</p>
        <button type="button" class="btn4-carousel">{{ 'carousel-intro.buy-bundle' | translate }}</button>
      </div>
    </div>

    <div class="carousel-item">
      <img src="assets/icons_resize/n9.png" class="d-block w-100 img-fluid" alt="bg">
      <div class="carousel-caption d-block col-md-5 offset-5">
        <h3  class="h2">{{ 'carousel-intro.manage-your' | translate }} <span class="target">{{ 'carousel-intro.target-saving' | translate }}</span>
          {{ 'carousel-intro.akao-txt' | translate }} <span class="target">{{ 'carousel-intro.lending' | translate }}</span> {{ 'carousel-intro.njangi-txt' | translate }}</h3>
        <p>{{ 'carousel-intro.grow-txt' | translate }}</p>
        <button type="button" class="btn5-carousel">{{ 'carousel-intro.get-started' | translate }}</button>
      </div>

    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon control" aria-hidden="true" ></span>
    <span class="visually-hidden">{{'carousel.previous' | translate}}</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon control" aria-hidden="true"></span>
    <span class="visually-hidden">{{'signup.next' | translate}}</span>
  </button>
</div>
</div>
