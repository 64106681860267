// This file can be replaced during build by using the `fileReplacements` array.

export const environment = {
  production: false,
  //
  // baseUrlPub: 'http://192.168.100.196:8001/api/v1/public/',
  // baseUrlPro: 'http://192.168.100.196:8001/api/v1/protected/',
  // baseUrlPayment: 'http://192.168.100.53:8001/api/v1/'

  baseUrlPub: 'https://monkap-api-dev.jan-it-solutions.com/api/v1/public/',
  baseUrlPro: 'https://monkap-api-dev.jan-it-solutions.com/api/v1/protected/',
  baseUrlPayment: 'https://payment-api-dev.jan-it-solutions.com/api/v1/'
};
