import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {ToastContainerModule, ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AuthInterceptorService} from "./interceptors/auth-interceptor.service";


import {LandingPageComponent} from "./landing-page-module/landing-page/landing-page.component";
import {HeaderComponent} from "./landing-page-module/components/header/header.component";
import {CarouselComponent} from "./landing-page-module/components/carousel/carousel.component";
import {MonkapIntroComponent} from "./landing-page-module/components/monkap-intro/monkap-intro.component";
import {HowMonkapWorksComponent} from "./landing-page-module/components/how-monkap-works/how-monkap-works.component";
import {WhyMonkapComponent} from "./landing-page-module/components/why-monkap/why-monkap.component";
import {DownloadMonkapComponent} from "./landing-page-module/components/download-monkap/download-monkap.component";
import {MonkapUsersComponent} from "./landing-page-module/components/monkap-users/monkap-users.component";
import {LearnMoreComponent} from "./landing-page-module/components/how-monkap-works/learn-more/learn-more.component";
import {CarouselModule} from "ngx-owl-carousel-o";
import {FooterComponent} from "./landing-page-module/components/footer/footer.component";

import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {NgxSpinnerModule} from "ngx-spinner";
import {NgSelectModule} from '@ng-select/ng-select';

//import ngx translate and http loader
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {FormsModule} from "@angular/forms";

import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import {MatChipsModule} from "@angular/material/chips";





@NgModule({
    declarations: [
        AppComponent,
        LandingPageComponent,
        HeaderComponent,
        CarouselComponent,
        MonkapIntroComponent,
        HowMonkapWorksComponent,
        WhyMonkapComponent,
        DownloadMonkapComponent,
        MonkapUsersComponent,
        LearnMoreComponent,
        PageNotFoundComponent,
        FooterComponent,

    ],
    imports: [
        MatExpansionModule,
        MatIconModule,
        NgxIntlTelInputModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        CarouselModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
          timeOut: 10000,
          progressBar: true,
            closeButton: true
          }
        ),
        ToastContainerModule,
        BrowserAnimationsModule,
        NgxSpinnerModule,
        NgSelectModule,
        NgxSkeletonLoaderModule,
        MatChipsModule,

    ],

    schemas: [CUSTOM_ELEMENTS_SCHEMA],

    providers:
        [{
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        }],


    bootstrap: [AppComponent]
})

export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
