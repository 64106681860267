import {Injectable} from '@angular/core';
import {LoginResponseDTO} from "../../models/dto/login-response-dto";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);

  }

  public getToken(): boolean | null {
    return !!localStorage.getItem('data');

  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }

  hasValue(key: string): boolean {
    return localStorage.hasOwnProperty(key);
  }

  getStorageData(): LoginResponseDTO {
    return JSON.parse(this.get('data') ?? "{}");
  }

  checkTokenExpiration(): boolean {
    return new Date(this.getStorageData().expiredIn) < new Date;
  }

  setStorageData(data: LoginResponseDTO) {
    this.set('data', JSON.stringify(data))
  }
}
