<div class="bg-white pt-5" id="why">
<div class="container" >
  <h3 style="text-align: center" class="mb-5 pt-5 text-color">{{'why-monkap.title' | translate}}</h3>
  <div class="row">
    <div class="col-md-5">
      <img src="../../../../assets/girl.PNG" style="width: 90%">
    </div>
    <div class="col-md-7">
      <div class="alert alert-danger" role="alert">
        <h5 style="color: #C8102E">{{'why-monkap.security' | translate}}</h5>
        <span style="color: black" class="mb-5">{{'why-monkap.info' | translate}}</span>
      </div>
      <div class="mb-3">
      <h4>{{'why-monkap.prove' | translate}}</h4>
      <span class="mb-5"> {{'why-monkap.moreprove' | translate}} </span>
      </div>
      <div class="mb-3">
      <h4>{{'why-monkap.growyf' | translate}}</h4>
      <span>{{'why-monkap.monkaphelp' | translate}}</span>
      </div>
    </div>
  </div>
</div>
</div>