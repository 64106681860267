import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';



@Component({
  selector: 'app-monkap-users',
  templateUrl: './monkap-users.component.html',
  styleUrls: ['./monkap-users.component.css']
})
export class MonkapUsersComponent implements OnInit {

  activeCard: any;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    autoplay: true,
    touchDrag: true,
    pullDrag: true,
    margin:25,
    dots: true,
    dotsData: true,
    navSpeed: 600,
    center: true,
    navText: ['<i class="fa fa-angle-left fa-2x"></i>', '<i class="fa fa-angle-right fa-2x"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      760: {
        items: 3
      },
      1000: {
        items: 3
      }
    },
    nav: true
  }

  dynamicSlides = [
    {
      id: 1,
      src:'assets/my-account/Account-pic.JPG',
      alt:'Side 1',
      title:'Vifieh Ruth'
    },
    {
      id: 2,
      src:'assets/my-account/Account-pic.JPG',
      alt:'Slide 2',
      title:'Chi Mah'
    },
    {
      id: 3,
      src:'assets/my-account/Account-pic.JPG',
      alt:'Slide 3',
      title:'Neh Carine'
    },
    {
      id: 4,
      src:'assets/my-account/Account-pic.JPG',
      alt:'Side 4',
      title:'Tobey Paul'
    },
    {
      id: 5,
      src:'assets/my-account/Account-pic.JPG',
      alt:'Side 5',
      title:'Tanwi Kalson'
    }
  ]

  constructor() {

  }

  ngOnInit(): void {

  }

  setActiveContainer(event: any) {
    this.activeCard = event.target.id;
  }

}
