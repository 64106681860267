<nav class="navbar navbar-expand-lg fixed-top">
  <div class="container-fluid">
    <div class="fit-nav">
      <a class="navbar-brand d-flex align-items-center" (click)="toTopPage()" *ngIf="!localStorageService.getToken()">
        <img class="monkap-logo-image" src="assets/images/logo.svg" alt="logo">
        <span class="monkap-logo ms-2">MyNkap</span></a>

      <a class="navbar-brand d-flex align-items-center" routerLink="/user/db" *ngIf="localStorageService.getToken()">
        <img class="monkap-logo-image" src="assets/images/logo.svg" alt="logo">
        <span class="monkap-logo ms-2">MyNkap</span></a>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav menu">
        <li class="nav-item">
          <a (click)="toMobileRecharge()">{{'shared.recharge-mob' | translate}}</a>
        </li>
        <li class="nav-item">
          <a (click)="toMoneyTransfer()">{{'footer.trsf' | translate}}</a>
        </li>
        <li class="nav-item">
          <a (click)="toMoneyTransfer()" routerLink="">{{'header.money-management' | translate}}</a>
        </li>
        <li class="nav-item">
          <a (click)="toBillPay()">{{'monkap-intro.bill' | translate}}</a>
        </li>
        <li class="nav-item">
          <a (click)="toWhy()">{{'why-monkap.title' | translate}}</a>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item" *ngIf="localStorageService.checkTokenExpiration() || !localStorageService.getToken()">
          <a class="nav-link" routerLink="/auth/login">{{'login.button' | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="localStorageService.checkTokenExpiration() || !localStorageService.getToken()">
          <a class="nav-link" routerLink="/auth/register">{{'header.sign' | translate}}</a>
        </li>
        <li class="nav-item languages">
          <select (change)="useLanguage($event)" [(ngModel)]="language" class="form-select" aria-label="Default select example">
            <option value="en" selected>🇬🇧&emsp;&emsp; ENG</option>
            <option value="fr">🇫🇷&emsp;&emsp; FR</option>
            <option value="es">🇪🇸&emsp;&emsp; ES</option>
            <option value="pt">🇵🇹&emsp;&emsp; PT</option>
          </select>
        </li>
      </ul>
    </div>
  </div>
</nav>
