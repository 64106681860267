<div class="main p-5">
  <div class="container">
    <h3 class="pb-5 blue-text">{{'monkap-users.title' | translate}} </h3>
    <div class="contain">
      <h5 class="mb-4" style="text-align: center">{{'monkap-users.sub-title' |translate}}</h5>
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let slide of dynamicSlides">
          <ng-template carouselSlide>
            <div class="card">
              <img src="{{ slide.src }}">
              <h5 class="py-3">{{ slide.title }}</h5>
              <div>
                <i class="fa-solid fa-quote-left me-2"></i>
                <span>{{'monkap-users.review' | translate}}</span>
                <i class="fa-solid fa-quote-right ms-2"></i>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</div>
